import paronApi from '@/utils/paron-api'
export default {
    getAllDealers() {
        return paronApi.get('dealer/all')
    },

    getDealerById(id) {
        return paronApi.get(`dealer/${id}`)
    },

    createDealer(data) {
        return paronApi.post('dealer/new', data)
    },

    editDealerById(id, data) {
        return paronApi.post(`dealer/${id}/edit`, data)
    },

    deleteDealerById(id) {
        return paronApi.delete(`dealer/${id}`)
    },
    getDealersByFilter(filter) {
        return paronApi.post('dealer/filtered', filter)
    }

}
